import React from 'react';
import './Footer.css';
import { Button } from '../../Button';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';
import { MdFingerprint } from 'react-icons/md';

function Footer() {
  return (
    <div className='footer-container'>
      <div className='footer-links'>
        <div className='footerstuff'>
          <div className='footerLeft'>
              <div className='footer-link-items'>
                <a className='PhoneNum' href="tel:225-261-1465">Phone: 225-261-1465</a>
                <a className='PhoneNum' href="tel:225-261-5706">Fax: (225) 261-5706</a>
                <br/>
                <text className='text'>18255 Magnolia Bridge Road</text>
                <text className='text'>Greenwell Spings, LA 70739</text>
                <br/>
                <a className='PhoneNum' href="email:tanksleyautomotive@gmail.com">tanksleyautomotive@gmail.com</a>
                <br/>
                <text className='text'>Mon - Fri 8:00 AM - 5:00 PM</text>
                <text className='text'>Sat - Sun Closed</text>
              </div>
          </div>
          <div className='map'>
            <div className='footer-link-items'>
              <iframe width="420" height="300" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=18255%20Magnolia%20Bridge%20Road%20Greenwell%20Springs+(Tanksley%20Automotive)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href='https://maps-generator.com/'></a>
            </div>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              TANKSLEY AUTOMOTIVE
            </Link>
          </div>
          <small className='website-rights'>Tanksley Automotive © 2023</small>
          <div className='social-icons'>
            Find us on Facebook!
            <Link
              className='social-icon-link'
              to={
                '//www.facebook.com/TanksleyAutomotive/'
              }
              target='_blank'
              aria-label='Facebook'
            >
              <FaFacebook />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;