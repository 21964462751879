import React from 'react';
import { Button } from './Button';
import './ServicesInfo.css';
import { FaFire } from 'react-icons/fa';
import { BsXDiamondFill } from 'react-icons/bs';
import { GiCrystalize } from 'react-icons/gi';
import { IconContext } from 'react-icons/lib';
import { Link } from 'react-router-dom';

function ServicesInfo() {
  return (
    <IconContext.Provider value={{ color: '#fff', size: 64 }}>
          <div className='pricing__section'>
          <h1 className='pricing__heading'>Services For All Years, Makes, and Models</h1>
          <div className='pricing__wrapper'>
              <div className='listLeft'>
                <ul>
                  <li>Air Conditioning</li>
                  <li>Timing Chains & Belts</li>
                  <li>Transmission Service</li>
                  <li>CV Joints</li>
                  <li>Electrical Diagnosis</li>
                  <li>Foreign & Domestic Repairs</li>
                  <li>Cooling Systems</li>
                  <li>Tune-Ups</li>
                  <li>Batteries</li>
                  <li>Brakes & ABS Brakes</li>
                  <li>Air Suspension Systems</li>
                  <li>Check Engine Light Diagnosis</li>
                  <li>Tires</li>
                  <li>Wheel Alignment</li>
                  <li>State Inspections</li>
                  <li>...And much, much more!</li>
                </ul>
              </div>
              <div className='listRight'>
                <img className='Picture' src='images/pronto.png' alt='Pronto' />
                <img className='Picture' src='images/ase.png' alt='ASE CERTIFIED' />
                <img className='Picture' src='images/bbb.png' alt='BBB' />
                <img className='Picture' src='images/MVI.png' alt='MVI' />
              </div>
          </div>
        </div>
    </IconContext.Provider>
  );
}
export default ServicesInfo;