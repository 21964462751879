//https://github.com/briancodex/react-website-v2/tree/starter
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import ContactBar from './components/ContactBar';
import Navbar from './components/Navbar';
import Home from './components/pages/HomePage/Home';
import Footer from './components/pages/Footer/Footer';
import Services from './components/pages/Services/Services';
import About from './components/pages/About/About';
import Contact from './components/pages/Contact/Contact';

function App() {
  return (
    document.title = 'Tanksley Automotive | Auto Repair | Greenwell Springs, LA',
    <Router>
      <ContactBar/>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/services" element={<Services/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/contact" element={<Contact/>}/>
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
