import React from 'react';
import HeroSection from '../../HeroSection';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';
import Pricing from '../../Pricing';
import ContactForm from '../../ContactForm';
import ContactSection from '../../ContactSection';
import GoToTop from '../../GoToTop';

function Contact() {
  return (
    document.title = 'Contact | Tanksley Automotive | Auto Repair | Greenwell Springs, LA',
    <> 
      <ContactSection />
      <ContactForm />
      <GoToTop />
    </>
  );
}

export default Contact;