import React from 'react';
import { Button } from './Button';
import './ServicesPage.css';
import { FaFire } from 'react-icons/fa';
import { BsXDiamondFill } from 'react-icons/bs';
import { GiCrystalize } from 'react-icons/gi';
import { IconContext } from 'react-icons/lib';
import { Link } from 'react-router-dom';

function ServicesPage() {
  return (
    <IconContext.Provider value={{ color: '#fff', size: 64 }}>
      <div className='pricing__section'>
          <h1 className='pricing__heading'>Our Services</h1>
          <div className='pricing__wrapper'>
              <div className='listLeft'>
                <h3>A/C Repair</h3>
                <img className='ACRepair' src='images/acrepair.jpg' alt='A/C Repair' />
              </div>
              <div className='listLeft'>
                <h3>Engine R&R</h3>
                <img className='Engine' src='images/RemoveAndReplaceEngine.jpg' alt='R&R Engine' />
              </div>
          </div>
          <div className='pricing__wrapper'>
              <div className='listRight'>
                <h3>Rear End Service</h3>
                <img className='ACRepair' src='images/rearEnd.jpg' alt='Rear End' />
              </div>
              <div className='listRight'>
                <h3>Transmission R&R</h3>
                <img className='Engine' src='images/TransmissionService.jpg' alt='Transmission R&R' />
              </div>
          </div>
          <div className='pricing__wrapper'>
              <div className='listLeft'>
                <h3>Wheel Alignment</h3>
                <img className='ACRepair' src='images/alignment.jpg' alt='Alignment' />
              </div>
          </div>
          <div className='pricing__wrapper'>
              <div className='listLeft'>
                <h3>Classics</h3>
                <img className='Classics' src='images/mustang.jpg' alt='Mustang' />
              </div>
          </div>
      </div>
    </IconContext.Provider>
  );
}
export default ServicesPage;