import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import {MdFingerprint} from 'react-icons/md'
import {FaBars, FaTimes} from 'react-icons/fa'
import {Button} from './Button'
import './ContactBar.css';
import { IconContext } from 'react-icons/lib';

function ContactBar() {
    const [click, setClick] = useState(false)
    const [button, setButton] = useState(true)

    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)

    const showButton = () => {
        if(window.innerWidth <= 960){
            setButton(false)
        } else {
            setButton(true)
        }
    }

    useEffect(() => {
        showButton()
    }, []);

    window.addEventListener('resize', showButton);
  return (
    <>
    <IconContext.Provider value={{color: '#fff' }}>
    <div className="contactbar">
        <div className='contactbar'>
                <img className='phone' src='images/phone.png' alt='phone'/>
                    <div className='contactbar-item'>
                        <a className='ConBarPhoneNum' href="tel:225-261-1465">225-261-1465</a>
                    </div>
                <img className='pin' src='images/pin.png' alt='pin'/>
                    <div className='contactbar-item'>
                        <a className='ConBarPhoneNum' href="address:18255 Magnolia Bridge Road Greenwell Springs, LA 70739">18255 Magnolia Bridge Road Greenwell Springs, LA 70739</a>
                    </div>
        </div>
    </div>
    </IconContext.Provider>
    </>
  )
}

export default ContactBar