import React from 'react';
import HeroSection from '../../HeroSection';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';
import Pricing from '../../Pricing';
import ServicesInfo from '../../ServicesInfo';
import ServicesPage from '../../ServicesPage';
import GoToTop from '../../GoToTop';

function Services() {
  return (
    document.title = 'Services | Tanksley Automotive | Auto Repair | Greenwell Springs, LA',
    <>
      <HeroSection {...homeObjThree} />
      <ServicesPage />
      <ServicesInfo />
      <GoToTop />
    </>
  );
}

export default Services;