export const homeObjOne = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Welcome to Tanksley Automotive',
    description:
      'Since 1996 Tanksley Automotive has been serving the Central / Greenwell Springs, Louisiana Area. We are a family-owned and operated automotive shop catering to all of your repair needs. Whether it be A/C Repair, Brake Service, Transmission Install, Front-End Alignment or Check Engine Light Diagnosis, we have you covered. We are a full-service shop and can handle any job big or small. We are located at 18255 Magnolia Bridge Road, Greenwell Springs, LA 70739. Give us a call at (225) 261-1465 or stop by today!',
    buttonLabel: 'Contact Us',
    imgStart: '',
    img: 'images/tanksley automotive.png',
    alt: 'Credit Card'
  };
  
  export const homeObjTwo = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '100% SECURE',
    headline: 'Stay protected 24/7 anywhere anytime',
    description:
      'We have you covered no matter where you are located. Over 140 locations worldwide to ensure you have access anytime',
    buttonLabel: 'Learn More',
    imgStart: '',
    img: 'images/svg-5.svg',
    alt: 'Vault'
  };
  
  export const homeObjThree = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'EASY SETUP',
    headline: 'Super fast and simple onboarding process',
    description:
      "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
    buttonLabel: 'Start Now',
    imgStart: 'start',
    img: 'images/svg-7.svg',
    alt: 'Vault'
  };
  
  export const homeObjFour = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'DATA ANALYTICS',
    headline: 'Every transaction is stored on our secure cloud database',
    description:
      'Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.',
    buttonLabel: 'Sign Up Now',
    imgStart: 'start',
    img: 'images/svg-8.svg',
    alt: 'Vault'
  };