import React from 'react';
import HeroSection from '../../HeroSection';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';
import Pricing from '../../Pricing';
import AboutSection from '../../AboutSection';
import GoToTop from '../../GoToTop';

function About() {
  return (
    document.title = 'About | Tanksley Automotive | Auto Repair | Greenwell Springs, LA',
    <>
      <HeroSection {...homeObjOne} />
      <GoToTop />
    </>
  );
}

export default About;