export const homeObjOne = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Exclusive Access',
    headline: 'Unlimited Transactions with zero fees',
    description:
      'Get access to our exclusive diamond card that allows you to  send unlimited transactions without getting charged any fees',
    buttonLabel: 'Get Started',
    imgStart: '',
    img: 'images/svg-1.svg',
    alt: 'Credit Card'
  };
  
  export const homeObjTwo = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '100% SECURE',
    headline: 'Stay protected 24/7 anywhere anytime',
    description:
      'We have you covered no matter where you are located. Over 140 locations worldwide to ensure you have access anytime',
    buttonLabel: 'Learn More',
    imgStart: '',
    img: 'images/svg-5.svg',
    alt: 'Vault'
  };
  
  export const homeObjThree = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'No job too large or small',
    headline: 'Service for your vehicle',
    description:
      "Here at Tanksley Automotive, we offer a wide variety of services for your vehicle. We can handle anything from a simple oil change to a complete engine overhaul. We also offer a wide variety of tires to fit your needs. We are a full service shop and can handle all of your automotive needs.",
    buttonLabel: 'Contact Us',
    imgStart: 'start',
    img: 'images/vette.jpg',
    alt: 'vette'
  };
  
  export const homeObjFour = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'DATA ANALYTICS',
    headline: 'Every transaction is stored on our secure cloud database',
    description:
      'Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.',
    buttonLabel: 'Sign Up Now',
    imgStart: 'start',
    img: 'images/svg-8.svg',
    alt: 'Vault'
  };