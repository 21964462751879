import React from 'react';
import { Button } from './Button';
import './HomeSection.css';
import { FaFire } from 'react-icons/fa';
import { BsXDiamondFill } from 'react-icons/bs';
import { GiCrystalize } from 'react-icons/gi';
import { IconContext } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";


function HomeSection() {
  return (
    <IconContext.Provider value={{ color: '#fff', size: 64 }}>
      <h1 className='TopText'>
      <motion.div
            className="title1"
            initial={{
              x: -100,
              scale: 0.1,
            }}
            animate={{
              x: 0,
              scale: 1,
            }}
            transition={{ duration: 1 }}
            >
            We Keep Your Vehicle On The Road!
          </motion.div>
      </h1>
      <div className='ShopPic'>
        <img src="images/ShopFrontAlt.jpg" alt="Tanksley Automotive"/>
      </div>
    </IconContext.Provider>
  );
}
export default HomeSection;