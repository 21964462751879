import React from 'react'
import HeroSection from '../../HeroSection'
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data' 
import Pricing from '../../Pricing'
import HomeSection from '../../HomeSection'
import ServicesInfo from '../../ServicesInfo'
import GoToTop from '../../GoToTop';

function Home() {
  return (
    document.title = 'Tanksley Automotive | Auto Repair | Greenwell Springs, LA',
    <>
        <HomeSection/>
        <HeroSection {...homeObjOne}/>
        <ServicesInfo/>
        <GoToTop />
    </>
  )
}

export default Home