import React from 'react';
import './ContactSection.css';
import { IconContext } from 'react-icons/lib';

function ContactSection() {
  return (
    <IconContext.Provider value={{ color: '#fff', size: 64 }}>
      <div className='pricing__section'>
          <h2 className='pricing__heading'>Contact Us</h2>
          <div className='ConUs'>
              <div className='conlistLeft'>
                <a className='ConPhoneNum' href="tel:225-261-1465">Phone: 225-261-1465</a>
                <text>Fax: 225-261-5706</text>
                <br/>
                <text>18255 Magnolia Bridge Road</text>
                <text>Greenwell Spings, LA 70739</text>
                <br/>
                <text>tanksleyautomotive@gmail.com</text>
                <br/>
                <text>Mon - Fri 8:00 AM - 5:00 PM</text>
                <text>Sat - Sun Closed</text>
              </div>
          </div>
      </div>
    </IconContext.Provider>
  );
}
export default ContactSection;